<template>
  <div>
    <el-row class="bgW">
      <el-col :span="18">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="门店">
            <el-select v-model="search.shop_type" clearable class="w100">
              <el-option
                v-for="item in options.shop_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
		  <el-form-item label="来源类型">
		    <el-select v-model="search.source_type" clearable class="w100">
		      <el-option
		        v-for="item in options.source_type"
		        :key="item.id"
		        :label="item.name"
		        :value="item.id"
		      />
		    </el-select>
		  </el-form-item>
      <el-form-item label="门店类型" prop="type">
        <el-select
          v-model="search.type"
          class="w100"
          clearable
          placeholder="门店类型"
        >
          <el-option
            v-for="item in options.type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="6" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary" class="mb10" size="mini" @click="taskFrame = true">添加任务</el-button>
      <el-button type="primary" class="mb10" size="mini" :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
      </el-row>


    <div class="lister">
      <!--列表-->
      <el-row >
        <el-col :span="24" class="xxixi">
          <el-table
            :data="tableData"
            size="small"
            max-height="650"
            border
            v-loading="loading"
            :span-method="objectSpanMethod"
            :cell-style="cellStyle"
            :header-cell-style="rowClass"
          >
            <ElTableColumn label="市场" prop="market_name" fixed/>
            <ElTableColumn label="门店" prop="shop_name"  fixed/>
            <ElTableColumn label="进店任务" prop="shop_task" />
            <ElTableColumn label="线上预约" prop="all_yuyue_num" />
            <ElTableColumn label="进店个数" prop="all_into_num" />
            <ElTableColumn label="疤痕进店" prop="all_bh_num" />
            <ElTableColumn label="疤痘进店" prop="all_dd_num" />
            <ElTableColumn label="项目进店" prop="all_xm_num" />
            <ElTableColumn label="(新单) 成交个数" prop="all_new_order_num" width="120" />
            <ElTableColumn label="新单金额" prop="all_new_order_money" />
            <ElTableColumn label="总成交额" prop="all_order_money" />
            <ElTableColumn label="进店率" prop="store_entry_rate" />
            <ElTableColumn label="成交率" prop="turnover_rate" />
            <ElTableColumn label="广告费" prop="advertising_expenses" />
            <ElTableColumn label="回访广告" prop="advertising_expenses_huifang" />
            <ElTableColumn label="团购广告" prop="advertising_expenses_tuangou" />
            <ElTableColumn label="新单ROI" prop="monthROI" />
            <ElTableColumn label="整体ROI" prop="allROI" />
          </el-table>
          <!-- <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          /> -->
        </el-col>
      </el-row>
    </div>
    <!-- 新增弹框 -->
    <el-dialog title="新增门店月度任务" :visible.sync="taskFrame"
    :close-on-click-modal="false" width="30%" @close="closeTask">
      <el-form
        ref="taskDetail"
        label-position="right"
        label-width="120px"
        :rules="rules"
        :model="taskDetail"
        class="frame"
        size="mini"
      >
        <el-form-item label="店铺名称:" prop="shop_id">
          <el-select
              v-model="taskDetail.shop_id"
              clearable
              remote
              filterable
              v-loading="loading"
              class="w300"
              :remote-method="remoteShop"
              placeholder="请输入店铺名称"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
        </el-form-item>
        <el-form-item label="任务数量" prop="task_num">
          <el-input-number class="w300" v-model="taskDetail.task_num" :min="-10000" :precision="0" ></el-input-number>
        </el-form-item>
        <el-form-item label="年/月" prop="moneth">
          <el-date-picker
            v-model="time"
            type="month"
            class="w300"
            value-format="yyyy-MM"
            @change="changeTime"
            placeholder="选择月份">
          </el-date-picker>
        </el-form-item>
        <div class="footerBtn mt50">
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('taskDetail')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import { getShopList } from "@/api/shop";
import { shopTask,monethShop } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class TaskDetail {
  shop_id = ""; // 店铺id
  task_num = 1; // 任务数量正整数
  year = ''; // 年份
  moneth = ''; // 月份
}
class Rules {
 shop_id = [
    { required: true, message: "请选择门店", trigger: "change" }
  ];
 task_num = [
    { required: true, message: "请输入任务数量", trigger: "change" }
  ];
 year = [
    { required: true, message: "请输选择年份", trigger: "change" }
  ];
 moneth = [
    { required: true, message: "请选择月份", trigger: "change" }
  ];

}
class Options {
  shop_id = [];

  source_type = [
    { id: 0, name: "全部" },
    { id: 7, name: "美团" },
	{ id: 1, name: "高德" },
    { id: 2, name: "回访" },
	{ id: 6, name: "抖团" },
	{ id: 3, name: "医疗美团" },
	{ id: 4, name: "黄V" },
	{ id: 5, name: "爱甲" },
  
 
  ];

  shop_type = [
    { id: 1, name: "门店" },
    { id: 2, name: "医院" }
  ];
  type = [
      {
        id: "1",
        name: "直营"
      },
      // {
      //   id: "2",
      //   name: "加盟"
      // },
      {
        id: "3",
        name: "直管"
      }
    ];
}
class Search {
  start_time = "";
  end_time = "";
  source_type =0;
  shop_type=1;
  type = "";
}
export default {
  name: "OrderList",
  components: {
    // pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      taskDetail: new TaskDetail(), //
      rules: new Rules(), // 校验
      options: new Options(), //选项
      page: new Page(), // 分页
      list: [], // 数据展示
      taskFrame: false,
      loading: false,
      time: '',
      exportS: false,
    };
  },
  computed: {
      tableData() {
        const res = this.list.reduce((res, item) => {
          item.maxnum = 0;
          if (item.list && item.list.length > 0) {
            const goods = item.list.map(goods => Object.assign({}, item, goods));
            goods[0].maxnum = item.list.length;
            res = res.concat(goods);
          } else {
            res.push(item);
          }
          return res;
        }, []);
        return res;
      }
    },

  mounted() {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01'
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
  },
  methods: {
    getList(option) {
      this.loading = true
      if (option === "search") this.page = new Page();
      monethShop({ ...this.search,export:0 }).then(res => {
        this.list = res.list;
        this.loading = false
      });
    },
    // 门店查找
   remoteShop(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    // 选择时间
    changeTime(e){
      var index = e.lastIndexOf("-");
      this.taskDetail.year = e.substring(0, index);
      this.taskDetail.moneth = e.substring(index+1,e.length);
    },
    // 关闭弹窗
    closeTask(){
      this.taskDetail = new TaskDetail()
      this.time = ''
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("添加此月度任务");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    confirm(){
      shopTask({ ...this.taskDetail }).then(res => {
      console.log(this.taskDetail)
         this.$message({
                type: "success",
                message: res.msg
                });
                this.taskFrame = false;
                this.getList();
      });
    },
    // 导出
    exportExcel() {
      this.exportS = true
      monethShop({...this.search,export:1}).then(res => {
        window.open(res.url);
        this.exportS = false
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: row.maxnum,
          colspan: 1
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    cellStyle({row, column, rowIndex, columnIndex}){
        if(columnIndex <= 1){
            return 'background:#CCFFFF;text-align:center'
        }
        if(columnIndex > 1 && columnIndex <= 7){
            return 'background:#FDE9D9;text-align:center'
        }
        if(columnIndex > 7 && columnIndex <= 12){
            return 'background:#93F8C9;text-align:center'
        }
        if(columnIndex > 12 && columnIndex <= 15){
            return 'background:#FFCC99;text-align:center'
        }
        if(columnIndex > 15){
            return 'background:#FFFF99;text-align:center'
        }
        
        return '';
      },
    rowClass({row, column, rowIndex, columnIndex}){
      return 'background:#E9EDEE;text-align:center'

    },
  }
};
</script>

<style lang="scss" scoped>
.footerBtn,.btnBox {
  text-align: right;

}

.lister,.xxixi{
	background-color: green ;
}
</style>
